<template>
  <div class="bind_wx" :class="{ isMobile }">
    <div class="qrcode" ref="qrCodeUrl"></div>
    <h2>扫一扫绑定聚客量化公众号</h2>
  </div>
</template>

<script>
import { getQrCode } from '@/api/wxchat'
import QRCode from 'qrcodejs2'

export default {
  components: {},

  data() {
    return {
      userInfo: this.$store.state.userInfo
    }
  },

  mounted() {
    this.getQrCode()
  },

  methods: {
    async getQrCode() {
      const qrcode = await getQrCode({
        redirect_uri:
          'https://www.jukequant.com/wxlogin?token=' + this.userInfo.token
      })
      this.creatQrCode(qrcode.data)
    },

    creatQrCode(text) {
      console.log(text.length)
      const qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: text, // 需要转换为二维码的内容
        width: 200,
        height: 200,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: 3
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.bind_wx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
