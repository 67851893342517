import request from '@/utils/request'

export function getQrCode(params) {
  return request({
    url: '/api/wechat/auth',
    method: 'get',
    params
  })
}

export function wxLogin(data) {
  return request({
    url: '/api/wechat/auth',
    method: 'post',
    data
  })
}
